<template>
  <Main>
    <div class="driver-detail">
      <div class="driver-detail__container" v-if="driver != null">
        <div class="driver-detail__header">
          <div class="driver-detail__button-back">
            <ButtonMain @click.native="backToList()" :buttonGrey="true">Назад к списку</ButtonMain>
          </div>
          <div class="driver-detail__data">Дата регистрации: {{ dateTransform }}</div>
          <div
            v-if="driver.moderated && driver.moderatedAfterEdit && driver.deletedAt == null"
            class="driver-detail__add-order"
          >
            <ButtonMain @click.native="handleAddOrder">Назначить заказ</ButtonMain>
          </div>
        </div>
        <ListItem
          :title="'ФИО:'"
          :value="`${driver.lastName} ${driver.firstName} ${driver.patronymic}`"
        />
        <ListItem :title="'Телефон:'" :value="driver.phone" />
        <ListItem
          :title="'Водительское удостоверение:'"
          :value="`${driver.driversLicenseSeries} ${driver.driversLicenseNumber}`"
        />
        <div v-if="driver.car != null" class="driver-detail__images">
          <div class="driver-detail__item-text">Фото авто:</div>
          <div class="driver-detail__image car">
            <div v-if="driver.car.frontPhoto != null" class="container">
              <img :src="driver.car.frontPhoto.photoUrl" />
            </div>
            <div v-if="driver.car.backPhoto != null" class="container">
              <img :src="driver.car.backPhoto.photoUrl" />
            </div>
            <div v-if="driver.car.rightPhoto != null" class="container">
              <img :src="driver.car.rightPhoto.photoUrl" />
            </div>
            <div v-if="driver.car.leftPhoto != null" class="container">
              <img :src="driver.car.leftPhoto.photoUrl" />
            </div>
          </div>
        </div>
        <div v-if="driver.car != null">
          <template v-if="driver.fullRegistration">
            <ListItem :title="'Тип кузова:'" :value="driver.car.bodyType.name" />
            <ListItem :title="'Марка:'" :value="driver.car.brand.name" />
            <ListItem :title="'Модель:'" :value="driver.car.model" />
            <ListItem :title="'Номер авто:'" :value="driver.car.carNumber" />
            <ListItem :title="'Год выпуска:'" :value="driver.car.yearOfCarManufacture" />
            <ListItem :title="'Номер прицепа:'" :value="driver.car.trailerNumber" />
            <ListItem :title="'Год выпуска:'" :value="driver.car.yearOfTrailerManufacture" />
            <div
              v-if="driver.car.carRegistrationCertificatePhoto != null"
              class="driver-detail__images"
            >
              <div class="driver-detail__item-text">Свидетельство регистрации авто:</div>
              <div class="driver-detail__image">
                <div class="container">
                  <img :src="driver.car.carRegistrationCertificatePhoto.photoUrl" />
                </div>
              </div>
            </div>
            <div
              v-if="driver.car.trailerRegistrationCertificatePhoto != null"
              class="driver-detail__images"
            >
              <div class="driver-detail__item-text">Свидетельство регистрации прицепа:</div>
              <div class="driver-detail__image">
                <div class="container">
                  <img :src="driver.car.trailerRegistrationCertificatePhoto.photoUrl" />
                </div>
              </div>
            </div>
          </template>
        </div>
        <ListItem v-if="driver.moderated" :title="'Тип авто:'" :value="typeOfCar" />
        <div class="driver-detail__footer">
          <div>
            <ButtonMain
              v-if="driver.deletedAt == null"
              :buttonDark="true"
              @click.native="handleOpenDeleteModal"
              >Удалить</ButtonMain
            >
            <ButtonMain v-else :buttonDark="true" @click.native="handleOpenRestoreModal"
              >Восстановить</ButtonMain
            >
          </div>
          <div v-if="!driver.moderated">
            <ButtonMain @click.native="handleOpenConfirmModal"> Одобрить </ButtonMain>
          </div>
          <div v-if="driver.moderated && !driver.moderatedAfterEdit">
            <ButtonMain @click.native="handleOpenConfirmDriverChangesModal">
              Повторно одобрить
            </ButtonMain>
          </div>
        </div>
      </div>
    </div>
    <DefaultModal
      class="driver-detail__confirm-modal"
      v-if="moderatedModal"
      :on-close="handleCloseConfirmModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseConfirmModal" :on-success="handleModerated">
        <template v-slot:description>
          <p>
            Вы уверены, что хотите одобрить водителя {{ driver.lastName }} {{ driver.firstName }}
            {{ driver.patronymic }}?
          </p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      class="driver-detail__confirm-modal"
      v-if="driverChangesModal"
      :on-close="handleCloseConfirmDriverChangesModal"
    >
      <ConfirmModalContent
        :on-cancel="handleCloseConfirmDriverChangesModal"
        :on-success="handleDriverChanges"
      >
        <template v-slot:description>
          <p>
            Вы уверены, что хотите повторно одобрить водителя {{ driver.lastName }}
            {{ driver.firstName }} {{ driver.patronymic }}?
          </p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      class="driver-detail__confirm-modal"
      v-if="deleteModal"
      :on-close="handleCloseDeleteModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseDeleteModal" :on-success="handleDelete">
        <template v-slot:description>
          <p>
            Вы уверены, что хотите удалить водителя {{ driver.lastName }} {{ driver.firstName }}
            {{ driver.patronymic }}?
          </p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      class="driver-detail__confirm-modal"
      v-if="restoreModal"
      :on-close="handleCloseRestoreModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseRestoreModal" :on-success="handleRestore">
        <template v-slot:description>
          <p>Вы уверены, что хотите восстановить водителя?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../constants/routes";
import driversApi from "../api/drivers";
import { dateTransform } from "../helpers/dateHelper";

import Main from "../templets/Main.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import ListItem from "../components/molecules/ListItem.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";
export default {
  name: "DriverDetail",
  data() {
    return {
      deleteModal: false,
      moderatedModal: false,
      driverChangesModal: false,
      restoreModal: false,
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ButtonMain,
    ListItem,
    DefaultModal,
    ConfirmModalContent,
  },
  methods: {
    handleOpenDeleteModal() {
      this.deleteModal = true;
    },
    handleCloseDeleteModal() {
      this.deleteModal = false;
    },
    handleDelete() {
      const url = `/${this.driver.id}`;
      this.$store.dispatch("drivers/deleteDriver", url).finally(() => {
        this.handleCloseConfirmModal();
        this.backToList();
      });
    },
    getDriver() {
      const { params } = this.$route;
      const url = `/${params.driverId}`;
      this.$store.dispatch("drivers/getDriverDetail", url);
    },
    backToList() {
      this.$router.push({
        path: ROUTE.DRIVERS_PAGE.replace(":page", 1),
      });
    },
    handleAddOrder() {
      this.$router.push({
        path: ROUTE.ETHER_ADD_ORDER_PAGE.replace(":driverId", this.driver.id),
      });
    },
    getTypesOfCars() {
      this.$store.dispatch("common/getTypesOfCars");
    },
    handleModerated() {
      const url = `/${this.driver.id}`;
      driversApi.approveDriver(url).then((res) => {
        if (res.data.moderated === true) {
          this.getDriver();
          this.handleCloseConfirmModal();
        }
      });
    },
    handleDriverChanges() {
      const url = `/${this.driver.id}`;
      driversApi.confirmDriverChanges(url).then((res) => {
        if (res.data.moderatedAfterEdit === true) {
          this.getDriver();
          this.handleCloseConfirmDriverChangesModal();
        }
      });
    },
    handleCloseConfirmDriverChangesModal() {
      this.driverChangesModal = false;
    },
    handleOpenConfirmDriverChangesModal() {
      this.driverChangesModal = true;
    },
    handleOpenConfirmModal() {
      this.moderatedModal = true;
    },
    handleCloseConfirmModal() {
      this.moderatedModal = false;
    },
    handleOpenRestoreModal() {
      this.restoreModal = true;
    },
    handleCloseRestoreModal() {
      this.restoreModal = false;
    },
    handleRestore() {
      const url = `/${this.driver.id}`;
      this.$store.dispatch("drivers/restoreDriver", url).finally(() => {
        this.handleCloseRestoreModal();
        this.backToList();
      });
    },
  },
  computed: {
    ...mapState("drivers", ["error", "driver", "loadingProcess"]),
    ...mapState("common", ["typesOfCars"]),
    dateTransform() {
      return dateTransform(this.driver.registerAt);
    },
    typeOfCar() {
      let type = "";
      if (this.driver.car.typeOfCar.id != 5) {
        type = `${this.driver.car.typeOfCar.name}, ${this.driver.car.typeOfCar.description}`;
      } else {
        type = `${this.driver.car.typeOfCar.name},
        До ${this.driver.car.weight} т, Д ${this.driver.car.length} м,
        Ш ${this.driver.car.width} м, В. ${this.driver.car.height} м, V ${this.driver.car.volume} м3`;
      }
      return type;
    },
  },
  mounted() {
    this.getDriver();
    this.getTypesOfCars();
  },
};
</script>

<style lang="scss" scoped>
.driver-detail {
  width: 100%;
  font-size: $g-font-base-size;

  &__container {
    padding: 12px 18px;
  }

  &__header {
    @include flex-row;

    position: relative;
    justify-content: space-between;
    padding-right: 70px;
    padding-bottom: 20px;
  }

  &__add-order {
    position: absolute;
    top: 120%;
    right: 70px;
  }

  &__data {
    display: flex;
    align-items: flex-end;
    color: $mainSecondTextColor;
  }

  &__button-back {
    width: 140px;
  }

  &__item-text {
    width: 250px;
    color: $mainSecondTextColor;
  }

  &__text {
    @include flex-row;

    padding: 5px 0;
  }

  &__images {
    padding: 5px 0;
  }

  &__image {
    @include flex-row;

    padding: 5px 0;

    .container {
      width: 158px;
      height: 100px;
      margin-right: 10px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__image.car {
    .container {
      border: 1px solid #131313;

      img {
        padding: 5px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 336px;
    padding-top: 30px;

    div {
      width: 48%;
    }
  }

  &__type-car {
    padding-top: 5px;
  }
}
</style>
