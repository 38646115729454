<template>
  <div class="list-item">
    <div class="list-item__title">{{ title }}</div>
    <div v-if="!link" class="list-item__value">{{ value }}</div>
    <div v-else class="list-item__value">
      <a :href="linkUrl" target="_blank">{{ value }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "listItem",
  props: {
    title: {
      type: [String, Number],
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    link: {
      type: Boolean,
      default: false,
    },
    linkUrl: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  @include flex-row;

  padding: 5px 0;

  &__title {
    min-width: 270px;
    color: $mainSecondTextColor;
  }

  &__value {
    a {
      color: $mainColor;
      text-decoration: underline;
    }
  }
}
</style>
